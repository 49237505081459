/*.hide-navigation-item {
    display: none !important;
}*/
.HomeImage {
    max-width: 100%;
    width: 80%;
    max-height: 200px;
    opacity: 0.8;
    /*height: 5%;*/
    /*background-size: cover;*/
    left: 0px;
    top: 0px;
    z-index: -1;
}
.align-left{
  text-align:left;
}
/*.bg {
    background-color: #add8e6;
    width: 100%;
    min-width: 100%;

}*/
#background {
    width: 100%;
    height:200px;
    /*height: 50%;*/
    position: relative;
    left: 0px;
    top: 0px;
    z-index: -1; /* Ensure div tag stays behind content; -999 might work, too. */
}

.stretch {
    width: 100%;
    height: 100%;
}


/*Navigation Bar css*/
.bg1 {
    border: solid;
}
/*Old Blue colour
*/
.nav-bg-color {
    background-color: #5161ce;
}

#navbarExample01 {
    align-items: end;
}

.navig-link {
    color: white;
}

.ul, li {
    display: block;
    padding: 0px 10px;
    
    margin-left: 0px;
    position: relative;
}
.container {
}

.container ul li :hover {
    font-weight: bold;
}
.mrleft-nav {
    /*margin-left: 5px;*/
    margin: auto;
}
.AiOutlineMenu-col-blue {
    color: #5161ce;
}