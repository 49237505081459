body {
    margin: 0;
    font-family: "Times New Roman", Times, serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html, body, #root {
    height: 100%;
}
#root {
    display: flex;
    flex-direction: column;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/*body {
    background-color: #E7E8D1;
}*/
/*.btn-primary {
    background-color: #B85042;
}
.nav-bg-color {
    background-color: #A7BEAE;
}
.title {
    color: #B85042;
}*/

/*7. Raspberry pink and dark chocolate*/
/*body {
    background-color: #FBEAE7
}
.btn-primary {
    background-color: #552619;
}

.nav-bg-color {
    background-color: #B2456E;
}
h1.title {*/
/*Grievance Portal*/
/*color: #B2456E;
}
.title {*/
/*page Name*/
/*color: #B85042;
}

.table {
    background-color: #efd4d4;
}
.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
}*/



.bg-primary {
   
    /*background-color: rgba(59, 113, 202, var(--mdb--bg-opacity));*/
    /*opacity: 1;*/
}


/*--mdb--bg-opacity: 1;
background-color: rgba(59, 113, 202, var(--mdb--bg-opacity));
}

--mdb-bg-opacity: 1;
background-color: rgba(var(--mdb-primary-rgb), var(--mdb-bg-opacity)) 
*/


/*9. Light blue and cobalt
*/

/*body {
    background-color: #CADCFC;
}

.btn-primary {
    background-color: #00246B;
}

.nav-bg-color {
    background-color: #00246B;
}

h1.title {*/
    /*Grievance Portal*/
    /*color: #31473A;
}

.title {*/
    /*page Name*/
    /*color: #B85042;
}

.table {
    background-color: #8AB6F9;
}

.grid-item {
    background-color: rgba(255, 255, 255, 0.8);
}*/

/*TODO
.bg-white {
    --mdb-bg-opacity: 1;
    background-color: rgba(var(--mdb-white-rgb), var(--mdb-bg-opacity)) !important;*/