.App {
    text-align: center;
    /*font-family:Arial, Times, serif;*/
    font-family: "Times New Roman", Times, serif !important;
}
.css-10hburv-MuiTypography-root {
    font-family: "Times New Roman", Times, serif !important;
}
.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background-color: #3d85c6 !important;
}
.title {
    text-align: center;
    /*padding-right: 10%;*/
    padding-top: 2%;
    color: #3d85c6;
}

.right-nav-bg-color {
    background-color: #3d85c6;
}

.nav-bg-color {
    background-color: #3d85c6;

}

.css-1umppoh {
    background-color: unset !important;
}

.css-1mcnwpj-MuiList-root {
    background-color: unset !important;
}

.css-1ex1afd-MuiTableCell-root {
    font-family: unset !important;
}

.css-hbhl5g-MuiTableCell-root {
    font-family: unset !important;
}

.css-1ygcj2i-MuiTableCell-root {
    font-family: unset !important;
}
.css-yb0lig {
    font-family: unset !important;
}
/*.btn-primary {
    background-color: red;
}
*/
/*.title {
    color: red;
}*/
/*#navbarExample01 {
    position:fixed;
}
*/ /*#navbarExample01,ul,li{
position:center;
}*/
.navig-link {
    color: white;
}
/*.align-right-profile {
    margin-left: 100% !important;
}
*/

.ul, li {
    /*display: block;*/
    padding: 0px 20px;
    margin-left: 3px;
    position: relative;
}

.nav-bg-color ul li a:hover {
    color: white;
    font-weight: bold;
    border-bottom: 1px solid white;
    opacity: 0.9;
}

.container-fluid {
    width: 100% auto!important;
}

.nav-bg-color {
    /*    background-color: red;
*/ /*    align-items: center;
*/ /*padding-left:50%;*/
    /*padding-left:25%;*/
}

.mx-height {
    max-height: 35px;
}

.padding-footer-bottom {
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}

.root {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
}
.settings-btn-align-left {
    margin-right: 10px;
    padding-right: 5px;
    align-content: flex-end;
}
/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}*/
/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}
/*
.App-link {
  color: #61dafb;
}
*/
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

/*.btn-check:checked + .btn-primary:hover, .btn-check:active + .btn-primary:hover, .btn-primary:active:hover, .btn-primary.active:hover, .btn-primary.show:hover {*/
/* background-color: #3566b6; */
/*}*/
/*.btn-primary:focus {*/
/*background-color: coral !important;*/
/*opacity:0.5;
}*/
.input-group .btn {
    line-height: 1;
}

.input-group .btn {
    position: relative;
    z-index: 2;
}

.btn-primary {
    /* background-color: #31473A; */
}

.mrleft {
    margin-left: 30px;
}

.mrleft {
    margin-left: 30px;
}

.mrleft {
    margin-left: 30px;
}
.bg-primary-col {
    background-color: #3d85c6;
}
.btn-white {
/*Blue col*/
    background-color: #3d85c6;
}

select {
    background-color: #3d85c6;
    color: white;
}

    select:after {
        background-color: #7C8363;
        color: white;
    }
body {
    background-color: #EDF4F2;
}

.css-qold7s-MuiTableCell-root{
    font-family:unset !important;
}
thead{
    font-weight:bold;
}
/*.css-1jvps14-MuiTableContainer-root {
    width: 100%;
    overflow-x: auto  !important;
        }
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    overflow-x: auto !important;
    overflow-y: auto;
}*/
/*.css-fikjyc-MuiTableCell-root-MuiTablePagination-root{
    overflow:auto unset !important;
}
.css-1jvps14-MuiTableContainer-root {*/
    /* width: 100%; */
    /*overflow-x: auto unset !important;
}*/
.css-rorn0c-MuiTableContainer-root {
    /*width: 100% unset !important;*/
    overflow-x:  unset !important;
}
.css-1jr8942-MuiPaper-root-MuiTableContainer-root {
    /*width: 100% unset !important;*/
    overflow-x:  unset !important;
}
.css-kge0eu {
    overflow-x: unset !important;
}
.css-1ghvl04 {
    overflow-x: unset !important;
}