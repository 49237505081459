.RegisterContent
{
    align-content:baseline;
}
.d-flex1 {
    gap: 15px;
    margin-bottom:15px;
    margin-top:5px;

}
.form-check-label{
    align-content:initial;
}
.pd{
    margin-bottom:10px;
}
.mr-top-ps{
    padding-top:15px;
}