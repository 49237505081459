.mrtop{
    margin-top:30px;
}
.mr-bottom{
   padding-bottom:1.5%;
}
.btn-mr-top {
    margin-top: 20px;
}
.mrleft-1{
    margin-left:40px;
}
.align-left {
    align-content: left;
    margin-left: 28%;
}
.form-helper{
    
}
.help-btn{
    color:mediumseagreen;
}
.border-div {
    border: 1px solid #6495ed;
}
#submit-cancel-btn {
    align-content: left;
    margin-left: 6%;
    margin-top: 3%;
    margin-bottom: 3%;
}
#blank-div-to-show-hide{
    /*sbackground-color:antiquewhite;*/
}
h3 {
    text-align: left;
    margin-left: 6%;
    font-weight: bold;
}
#col2 {
    margin-left: 10px;
}
.select-option{
    height:35px;
}
#wrapper3 {
    display: flex;
    align-self: center;
    text-align: left;
    border: 0.5px;
    /*border-color: black;
    border-radius: 2px 5px 5px;
    border: 1px solid black;*/
    margin-left: 5%;
    margin-right: 5%;
    padding: 2%;
    margin-bottom: 3%;
}
.distance-between {
    margin-top:5px;
    padding:4px;
}
.required:after {
    color: #e32;
    content: ' *';
    display: inline;
}


.grid-container2 {
    display: grid;
    position:relative;
    grid-template-columns: 36% auto;
    /*align-self: center;*/
    gap: 5px 5px;
    align-content: center;
    /*max-inline-size: 50%;*/
    max-width: 80%;
    /*margin-left: 2%;*/
    margin: 5%;
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 0%;
}

.grid-item2 {
    /* background-color: rgba(255, 255, 255, 0.8);*/
   /* border: 1px solid #CFCDCB;*/
    padding: 5px;
    text-align: left;
}
.grid-item3 {
    /* background-color: rgba(255, 255, 255, 0.8);*/
    /* border: 1px solid #CFCDCB;*/
    padding: 5px;
    text-align: left;
}
.justify-content-between{
    margin-left:10px;
}
.input-group-justify-left {
    justify-content: unset !important;
    display: flex;
}
