.form-control {
    /*maxHeight: 80;*/
}
.ul-style {
        min-width:80px;
}
.bold {
    font-weight: bold;
}
.mrleft {
    margin-left: 30px;
}
#div-align-center {
    margin-top: 2%;
    margin-left: 2%;
}
.input-group {
    text-align: center;
    /* margin-left: 2%;
    margin-top: 3%;*/
    margin-top: 2% !important;
    justify-content: center !important;
}
.btn-white{
    color:white;
}
.input-group-inside-btn {
    margin: auto;
    border: unset;
}
    .mrright {
        margin-left: 10px;
    }

.mrtop {
    margin-top: 5px;
}
.mrtop4 {
    margin-top: 4px;
}

/*.search-label {
    margin: auto;*/
  /*  margin-right: 10px;
    margin-left: 10px;*/
/*}
.submit-div-search {
    margin: auto;*/
    /* text-align: left;
    margin-left: 15%;*/
/*}*/
.submit-div {
/*    text-align: left;
    margin-left: 25%;*/
    margin-block: 3%;
}

.align_center {
    /* align-content: center;
    align-items: center;
    text-align: center;*/
    /*position: fixed;*/
   
    margin-top: 30px;
    /*align-self: center;*/
    /*align-content:center;*/
    /*transform: translate(-50%, 0);*/
}

.align-table-center {
    align-self: center;
    margin:auto;
}

.grid-container {
    display: grid;
    /*position:relative;*/
    grid-template-columns: auto auto;
    align-self: center;
    gap: 5px 5px;
    align-content: center;
    /*max-inline-size: 50%;*/
    max-width:70%;
    /*margin-left: 2%;*/
    margin:auto;
    padding: 0%;
}

.grid-item {
   /* background-color: rgba(255, 255, 255, 0.8);*/
    border: 1px solid #CFCDCB;
    padding: 5px;
    text-align: left;
    
}
p{
    margin-top:5px;
    margin-bottom:5px;
}
.position-center {
    margin-top: 5px;
    margin-bottom: 5px;
    vertical-align: central;
}
.submit-cancel-div{
   margin:auto;
}
#wrapper {
    display: flex;
    align-self: center;
    text-align: left;
    border: 1px;
    border-color: black;
    border-radius: 2px 5px 5px;
    border: 1px solid black;
    margin-left: 5%;
    margin-right: 5%;
    padding: 1.5%;
    margin-bottom: 3%;
}

#wrapper2 {
    display: flex;
    align-self: center;
    margin: auto;
    width: 50%;
    padding: 10px;
}

#col2 {
    margin-left: 10px;
}

.margin-button {
    margin-bottom: 2px;
}
.required:after {
    color: #e32;
    content: ' *';
    display: inline;
}
.btn:disabled{
    opacity:0.6;
}
select:disabled{
    opacity:0.6;
}
.css-1qqwgcu{
    font-family:unset !important;
}