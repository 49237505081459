.align-left {
    margin-left: 2%;
}
.align-left-10 {
    margin-left: 2%;
}
.tno-col{
    color:darkorange;
}
.h4-green {
    color: green;
   /*margin-left:50px;*/
   text-align:left;
/*    margin-top: 1%;
*/    /*border: 0.5px;*/
   /* border-color: green;
    border-radius: 2px 5px 5px;
    border: 1px solid black;
    padding: 0.5%;*/
    margin-left: 2%;
   
}
#wrapper4 {
    display: flex;
/*    align-self: center;
*/    text-align: left;
    /*border: 1px;*/
   /* border-color: black;
    border-radius: 2px 5px 5px;
    border: 1px solid black;*/
    /*margin-left: 2%;*/
    /*margin-right: 5%;*/
    /*padding: 1.5%;*/
    margin-bottom: 3%;
}
.download-pdf-btn{
background-color:forestgreen;
margin-top:unset;
margin-bottom:10px;
}
#blue-text{
    text-emphasis-color:blue;
    font-weight:bold ;
    color:white;
    text-decoration-color:blue;
}



.grid-container-summary {
    display: grid;
    /*position:relative;*/
    grid-template-columns: auto auto;
    /*align-self: center;*/
/*    gap: 5px 5px;*/
    align-content: center;
    /*max-inline-size: 50%;*/
    max-width: 50%;
    /*margin-left: 2%;*/
    align-self: flex-start;
        margin: 0.3%;
    padding: 0%;
}

.grid-item-summary {
    /* background-color: rgba(255, 255, 255, 0.8);*/
    /*border: 1px solid #CFCDCB;*/
    padding: 0.5px;
    text-align: left;
}