.align_center {
   /* align-content: center;
    align-items: center;
    text-align: center;*/
    /*position: fixed;*/
    left: 40%;
    align-self:center;
    /*transform: translate(-50%, 0);*/
}
#download_btn[disabled] {
    background: grey !important;
}
/* The container */
.container {
    display: block;

    /**/ /* padding-left: 35px;
    margin-bottom: 12px;*/
    cursor: pointer;
    font-size: 15px;
   /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;*/
}
/* Hide the browser's default checkbox */
/*.container input {
  
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}*/
/* Create a custom checkbox */
.checkmark {
   
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
   
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.align-table-center {
    align-self: center;
}

#tableID {
    width:100%;
}
.form-control {
    /*maxHeight: 80;*/
}

.bold {
    font-weight: bold;
}

.mrleft {
    margin-left: 30px;
}
p{
    margin-top:10px;
}
.mrright {
    margin-left: 10px;
}

.mrtop {
    margin-top: 10px;
}

.search-label {
    margin-right: 10px;
    margin-left: 10px;
}

.grid-container {
    display: grid;
    /*position:relative;*/
    grid-template-columns: auto auto;
    align-self: center;
    gap: 5px 5px;
    align-content: center;
    /*max-inline-size: 50%;*/
    max-width: 70%;
    /*margin-left: 2%;*/
    margin: auto;
    padding: 0%;
}

.grid-item {
    /* background-color: rgba(255, 255, 255, 0.8);*/
    border: 1px solid #CFCDCB;
    padding: 5px;
    text-align: left;
}
.grid-container3 {
    display: grid;
    /*position:relative;*/
    grid-template-columns: auto;
    align-self: center;
    gap: 5px 5px;
    align-content: center;
    /*max-inline-size: 50%;*/
    max-width: 70%;
    /*margin-left: 2%;*/
    margin: auto;
    padding: 0%;
}
#wrapper {
    display: flex;
    align-self: center;
    text-align: left;
    border: 1px;
    border-color: black;
    border-radius: 2px 5px 5px;
    border: 1px solid black;
    margin-left: 5%;
    margin-right: 5%;
    padding: 1.5%;
    margin-bottom: 3%;
}

#wrapper2 {
    display: flex;
    align-self: center;
    margin: auto;
    width: 50%;
    padding: 10px;
}

#col2 {
    margin-left: 10px;
}
.Dissatisfiedbtn {
    /*color:red;*/
    background-color: #ff1a1a !important;
    /*opacity:50%;*/
}
.Satisfiedbtn {
    background-color: forestgreen !important;
}
.escalate-checkbox {
    tab-size: 14;
    background-color: blue;
    transform: scale(1.5);
}
.escalate-btn {
    background-color: #FBBF77 !important;
    border: 1px solid #FB7D77;
    border-radius: 5px 4px;
    tab-size: 5px;
}
.Withdrawbtn {
    background-color: rebeccapurple !important;
}

.input-group{
   /* margin:auto;
    padding:initial;*/
   /* padding-right:15%;
    padding-left:0px;
    margin-left:0px;*/
    width:100% !important;
}
.nav-path-align {
    text-align: left;
    /*width: 100%;
    align-content: flex-start;*/
    margin-left: 25px;
    /*padding-left: 10px;*/
    /*padding-right: 80%;*/
    margin-top: 5px;
}