.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.mrtop-otp-btn{
    padding:5px;
    margin-top:10px;
    margin-right:50px;
    /*max-width:300px;*/
}
@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
}
.input-error {
    border: red 1px solid;
}
